.post-wrapper {
  hr {
    border-color: $primary_color !important;
  }

  h4 {
    font-size: 14px;
    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .post, a.post {
    position: relative;
    display: inline-block;
    height: 100%;

    @media screen and (min-width: 768px) {
      padding-bottom: 35px;
    }

    text-transform: none;
    letter-spacing: normal;
    font-size: inherit;

    .post-link {
      text-transform: none;
      letter-spacing: 0;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        font-size: 20px !important;
      }
    }

    .post-content {
      line-height: 20px;
      color: #4a4a4a;
      font-family: "Sofia Pro", sans-serif;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      text-transform: none;
      font-size: 14px !important;

      @media screen and (max-width: 767px) {
        font-size: 14px !important;
      }

    }

    &:hover, &:focus {
      p.post-details-rounting{
        color: $primary_color !important;
      }
      h4.post-details-title{
        color: $secondary_color !important;
        transition: .2s ease-in-out !important;
      }
    }

  }
  p.post-routing-link {
    text-transform: uppercase !important;
    font-size: 12px;
  }
  .post-details {

    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
    }

    p {
      &.post-details-rounting, &.post-routing-link {
        text-transform: uppercase !important;
        font-size: 12px;
      }

      &.post-details-content {
        font-size: 16px !important;
        color: #4a4a4a;
      }
    }

    h4.post-details-title {
      font-size: 28px;
      font-weight: 600;
      text-transform: none;
      margin-bottom: 25px;
    }
  }
}

.post-data {
  display: inline-block;
  text-transform: uppercase;
  color: #a1a1a1;
  font-size: 12px !important;
  margin: 0;
  padding: 0 0 15px 0;

  &.post-data-relative {
    position: relative;
    bottom: inherit;
  }
  @media screen and (min-width: 768px) {
    position: absolute;
    bottom: 15px;
    left: 0;
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    font-size: 12px !important;
  }

}