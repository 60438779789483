.bg-custom-light {
  background-color: $light_color;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  &.bg-routing {
    padding-bottom: 5px;
  }

  &.prefooter {

    @media only screen and (max-width: 768px) {
      padding-bottom: 10px;
    }
  }
}

.bg-custom-light-standard {
  background-color: $light_color;
  padding-top: 1px;
  padding-bottom: 20px;

  @media only screen and (max-width: 767px) {
    padding-top: 0px;
    padding-bottom: 15px;
  }

  &.bg-white {
    background-color: #ffffff;
    padding-bottom: 60px;
    @media only screen and (max-width: 767px) {
      padding-top: 0px;
      padding-bottom: 15px;
    }
  }

  &.bg-light-grey {
    background-color: $light_grey_color;
    padding-bottom: 60px;
    @media only screen and (max-width: 767px) {
      padding-top: 0px;
      padding-bottom: 15px;
    }
  }

  &.prefooter {
    padding-bottom: 80px;

    @media only screen and (max-width: 768px) {
      padding-bottom: 5px;
    }
  }

  hr {
    border-color: rgba(29, 55, 86, 0.3);
  }
}

.bg-custom-light-top {
  background-color: $light_color;
  padding-top: 40px;
  padding-bottom: 10px;

}

.bg-nav-overlay, .bg-nav {
  margin-bottom: -50px;
  margin-top: -50px;
  @media only screen and (max-width: 768px) {
    margin-bottom: -80px;
  }
}

.bg-nav {
  background-color: $light_color;
}

.bg-footer {
  background-color: $primary_color;
  padding-bottom: 20px;
  padding-top: 20px;
}

.bg-trasparent {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: transparent;
}

#sc-bank {

  background-color: $light_grey_color !important;

  .bg-white {
    @media only screen and (max-width: 767px) {
      background-color: $light_grey_color !important;
    }
  }

  .bg-grey {
    @media only screen and (max-width: 767px) {
      background-color: #ffffff !important;
        
    }
    &.bg-grey-mobile{
        @media only screen and (max-width: 767px) {
          background-color: #efefef !important;
        }
    }
  }
}