.fleft {
  width: 100%;
  float: left;
}

.fright {
  width: 100%;
  float: right;
}

.prevSlide {
  @media screen and (max-width: 767px) {
    padding-left: 25px;
  }

  .icon-arrow-left-rounded {
    @media screen and (max-width: 767px) {
      margin-top: 5px;
      font-size: 10px;
    }
  }
}

.nextSlide {
  .icon-arrow-right-rounded {
    @media screen and (max-width: 767px) {
      margin-top: 5px;
      font-size: 12px;
    }
  }
}

.date {
  visibility: hidden;

  @media screen and (max-width: 767px) {
    margin-left: 8px;
   margin-right: 10px;

  }

  .timeline-btn {
    outline: none;
    text-align: center;
    width: 100%;
    color: $primary_color;
    margin-left: 20px;
    margin-right: 20px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      .timeline-dot {
        .round {
          background-color: $primary_color !important;
        }
      }
    }

    .timeline-year {
      font-size: 14px;
      padding-bottom: 4px
    }

    .timeline-dot {
      z-index: 200;
      padding-bottom: 10px;

    }
  }
}

.col-1.timeline-arrows {
  margin-top: 22px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    margin-top: 25px;
  }

  &:hover {
    color: $secondary_color;
  }

  .icon-arrow-right-rounded {
    float: right;
  }

}

.for {

  .item {
    outline: none;
  }

  //  current slide
  .item.slick-slide.slick-current.slick-active {
    .pannello-story {
      background-color: #efefef;
      h2, p,a {
        color: $primary_color;
      }

    }
  }

  .item.slick-slide:not(.slick-current) {
    .pannello-story {
      h2, p, a {
        color: rgba(13, 62, 116, 0.2);
      }
    }
  }
}

.lineTime {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  background: #bbb;
  height: 1px;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  @media screen and (max-width: 767px) {

  }
}

.arrow-disabled {
  .icon-arrow-left-rounded, .icon-arrow-right-rounded {
    color: #bbb !important;
  }
}