@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?km9gbm');
  src: url('../fonts/icomoon.eot?km9gbm#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?km9gbm') format('truetype'),
  url('../fonts/icomoon.woff?km9gbm') format('woff'),
  url('../fonts/icomoon.svg?km9gbm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 15px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left-rounded:before {
  content: "\e900";
  border: 1.2px solid;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  padding: 10px;
  margin: 10px 0 10px 0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $primary_color;
    border-radius: 50%;
  }
}

.icon-arrow-right:before {
  content: "\e901";
}

.icon-arrow-right-rounded:before{
  content: "\e901";
  border: 1.2px solid;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  padding: 10px;
  margin: 10px 0 10px 0;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border: 1px solid $primary_color;
    border-radius: 50%;
  }
}

.icon-ceresio-logotype:before {
  content: "\e902";
  font-size: 200px;
}

.icon-ceresio-mark:before {
  content: "\e903";
  font-size: 50px;
  opacity: 0.5;

}

.icon-close:before {
  content: "\e904";
}

.icon-download:before {
  content: "\e905";
}

.icon-locked:before {
  content: "\e906";
}

.icon-menu:before {
  content: "\e907";
}

.icon-minus:before {
  content: "\e908";
  border: 1px solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding:3px;
  margin:0;
}

.icon-plus:before {
  content: "\e909";
  border: 1px solid;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding:3px;
  margin:0;

}

.circle {
  border: 1.15px solid $primary_color;
  border-radius: 0.5em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  width: 1.6em; height: 1.6em;
}


.circle:active,.circle:hover,circle:focus {
  background: $primary_color;
}

.round{
  height: 20px;
  width: 20px;
  border: 1px solid #bbb;
  background-color: $light_color;
  border-radius: 50%;
  display: inline-block;
  z-index: 200;
}
