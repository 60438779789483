
.white-popup {
    position: relative;
    background: #FFF !important;
    padding: 20px;

    width: auto;
    max-width: 1140px;
    margin: 30px auto;
      .modal-btn {
          border: 1px solid $primary_color;
      }
  }
  .blue-popup {
    position: relative;
    background: #1D3756!important;
    padding: 20px;
      color:white;
      
      h2{
          color:white;
      }

    width: auto;
    max-width: 100%;
    margin: 30px auto;
      .modal-btn {
          border: 1px solid white;
      }
  }
.country-modal-ceresio{
    position: fixed; top:0; left:0;
    margin:0; padding:0;
    height:100vh; width:100%;
    
    h2{
        margin-bottom:90px;
    }
    
    p{
        font-weight: 300; font-size:16px;
    }
    
    .claim{
        margin:0; padding:0 15px;
    }
    
    .modal-logo{
        width: 180px;
        margin-top: 33px; margin-bottom: 80px;
    }
    .modal-opt {
        white-space: nowrap;
    }
    .modal-btn {
        float: none!important; display: inline-block!important;
        letter-spacing:1px; text-transform: uppercase;
    }
    a {
        color: white;
    }

}
  #modal-content.row {
    padding-right: 40px;

    @media screen and (max-width: 767px) {
      padding-right: 10px;
    }
  }

  .popup-scroll {
    /* Overflow Scroll */
    overflow-y: auto;
    max-height: 450px;
    padding: 0px 30px 30px 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      padding: 0px 10px 10px 10px;
    }

    p {
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 20px;
    }
    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {

      background: $primary_color;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {

      background: $primary_color
    }

  }

  .modal-title {
    padding: 20px 60px 20px 30px;

    @media screen and (max-width: 767px) {
      padding: 10px 20px 10px 10px;


    }

    h4 {
      font-size: 12px;
    }

    p {
      margin-top: 15px;
      font-weight: 500!important;
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-weight: 500!important;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }

  .modal-opt {
    text-align: center;
    margin-bottom: 30px;


  }

  .modal-btn {
    font-size: 12px;
    border: 1px solid $primary_color;
    text-align: center !important;
        letter-spacing:1px; text-transform: uppercase;

    &.opt-out {
      padding: 6px 22px 6px 22px;
      margin-right: 20px;
      float: left;

      @media screen and (max-width: 767px) {
        display: inline-block;
        margin: 10px 0;
        float: none;
      }
      @media screen and (min-width: 768px) and (max-width: 1190px) {
        margin: auto;
        float: none;

      }

    }

    &.opt-in {
      margin-left: 20px;
      padding: 6px 38px 6px 38px;

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        margin-left: 0px;
        margin-top: 20px;
        float: none;
      }
      @media screen and (max-width: 767px) {
        margin: auto;
        float: none;
      }
    }

    &:hover {
      border-color: $secondary_color;
    }
  }

button.mfp-close {
  display: none !important;
}

.mfp-content {
  vertical-align: top !important;
  top: 150px !important;
}

