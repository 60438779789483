.icon-menu {
    font-size:30px!important;
}
.overlay{
    .card{
        h3, .h3{
            letter-spacing:1px;
        }
    }
}
.navbar-brand {
  color: $primary_color;

    > div {
        float:left;
        width:106px;
        margin-top:55px; margin-bottom:75px;

        @media only screen and (min-width: 768px) {
            width:180px;
            margin-top:70px; margin-bottom:80px;
        }
    }
}

.navbar-nav {
  align-items:center;
}

.nav-item.nav-item-custom {
  display: inline-block; position: relative;
  padding-left:50px;
  padding-right: auto;
  text-transform: uppercase;

  .active{
    position: absolute;
    background-color:$primary_color;
    height: 10px;
    width: calc(100% - 50px);
    top: -45px;
  }

  &.menu-desktop {
    padding-left:55px;
  }
  &.language {
    a {
      display:block; float:left;
      font-size:15px; text-transform:lowercase;
    }
  }

  .nav-link {
    font-size: 0.8rem;
    font-weight: 400;
      transition: .2s ease-in-out;

      .icon-close {
          font-size:27px;
      }
  }

    &.menu-desktop {
        margin-top:2px;
    }
}

.navbar-toggler {
  padding-right: 0; margin-top:-15px;
}

.nav-item-custom:focus, .nav-item-custom:hover {
  a:hover, a:focus {
    color: $secondary_color;
  }

  .navbar-toggler {
    .custom-toggler {
      border-color: $secondary_color;
    }
    .custom-toggler :hover, .custom-toggler :focus {
      color: $secondary_color;
      border-color: $dark_color;
    }
  }

  .nav-top-right-block {
    right: 10%;
    float: right;
    position: absolute;
  }

  .nav-Hr {
    margin-bottom: 10px;
  }

}
