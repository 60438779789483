//main: main.scss
.test {
  border: 1px solid red
}

h4.stat-title {
  font-size: 12px !important;

  @media screen and (max-width: 767px) {
    margin-bottom: 5px !important;
  }
}

.stat-text {
  font-size: 16px !important;
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
}

.stat-title {

  padding-top: 50px;
  margin-left: 0px;

  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
}

.standard-page-title {
  padding-left: 0px;
}

@media all and (-ms-high-contrast:none){
    *::-ms-backdrop, .standard-page-wrapper { width:100%; }
}
.standard-page-wrapper {
  .squared-link {
      display: inline-block;
    font-size: 12px !important; letter-spacing: 1px;
    border: 1px solid $primary_color;
    padding: 6px 40px 6px 40px; margin-top:35px;

    &:hover {
      border-color: $secondary_color;
    }

  }

  margin-top: 20px;

  h6 {
    text-transform: none;
    color: $dark_color;
    font-weight: 600;

  }

  .section-download {

    .row {
      &.link-download:hover {
        color: $secondary_color
      }
    }

    h4 {
      padding-right: 15px;
      margin-left: auto;

      @media only screen and (max-width: 767px) {
        margin-left: 15px;
      }

    }

    .icon-download {

      font-size: 30px;
      padding-top: 2px;
      float: right;
      font-weight: 600;
      @media only screen and (max-width: 767px) {
        float: none;
      }

    }

    .col-logo {
      float: right;
      padding-top: 3px;
      padding-right: 18px;

      @media only screen and (max-width: 767px) {
        margin-top: 1px;
        float: left;
        width: 10%;
      }
      @media only screen and (min-width: 767px) and (max-width: 990px) {
        padding-right: 0px;
        width: 10%;
      }

      .icon-download {
        color: $primary_color;
      }
    }

    .col-link {
      float: right;
      padding-top: 0px;
      padding-left: 0px;
      margin-right: 0;

      @media only screen and (max-width: 767px) {
        margin-left: 15px;
        float: left;
        width: 70%;
        line-height: 20px;
      }

      @media only screen and (min-width: 767px) and (max-width: 990px) {
        width: 80%;
      }

    }

    a {
      float: right;
      line-height: 20px;
      text-transform: none;
      font-size: 14px;
      font-weight: 500;

      @media only screen and (max-width: 767px) {
        float: none;
      }

    }
  }

  .title-section {
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  h4 {
    font-size: 15px;
    @media screen and (max-width: 767px) {
      font-size: 13px;
      margin-bottom: 20px;
    }
    &.lt {
      font-size: 14px !important;
    }
  }

  .soc-link {

    .soc-link-arrow {
      margin-top: 25px;
      @media screen and (max-width: 767px) {
        padding-right: 15px;
      }
      .icon-arrow-right-rounded {
        opacity: 0.1;
      }
    }

    hr {
      border-color: $primary_color;
    }
    a {
      h5 {
        margin-top: 15px;
        font-family: "Sofia Pro", sans-serif;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        font-weight: 600;
        text-transform: none;
        font-size:18px;

        &.small {
          font-size: 19px !important;
        }

        @media screen and (max-width: 767px) {

          &.small {
            font-size: 16px !important;
          }
          margin-bottom: 5px;
          font-size: 16px;
        }
      }

      h6 {
        margin-top: 15px;
        color: $primary_color;
        font-size: 30px;
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
          font-size: 18px;
          margin-top: 17px;
        }
      }
      h4 {
        color: $dark_color;
        text-transform: none;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      &:hover {
        h6, h5, .icon-arrow-right-rounded {
          opacity: 1;
          color: $secondary_color;
          transition: .2s ease-in-out;
        }
      }
    }
  }

}

.title-brand {

  .standard-page-content {

    p.pbt {
      margin-bottom: 30px !important;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px !important;
      }
    }

  }

  h1 {
    @media screen and (max-width: 767px) {

      font-size: 30px;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 25px;
    @media screen and (max-width: 767px) {
      line-height: 25px;
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  &.section-details {
    padding-bottom: 10px;
  }

  .more {
    & > .row {

      .col-8 {
        @media screen and (max-width: 767px) {
          line-height: 18px;
          font-size: 12px;
          letter-spacing: 0.5px;
        }
        .icon-arrow-right {
          @media screen and (max-width: 767px) {
            display: none;

          }
        }
      }
      .col-4 {
        margin-top: 10px;
        padding-left: 0;
        .icon-arrow-right {
          font-size: 14px !important;
          font-weight: 600;
        }
        @media screen and (min-width: 767px) {
          display: none;
        }
      }
    }
  }

  .row {
    &.list-rounded {

      margin-top: 15px;

      @media screen and (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 0px;
      }

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        .col-md-5 {
          margin-top: 15px;
        }
      }

      p {
        font-size: 16px;
        line-height: 26px;
        @media screen and (max-width: 767px) {
          font-size: 17px !important;
          line-height: 25px;
        }
      }

      h4 {
        font-size: 12px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 10px;
        }
      }

      .standard-page-inner-content {

        padding-top: 10px;
        padding-bottom: 200px;

        @media screen and (max-width: 767px) {
          padding-bottom: 50px;
        }
      }
    }

    .col-md-3 {
      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    .col-md-7 {
      margin-top: 10px;
      p {
        float: right;
        color: $primary_color;

        @media screen and (max-width: 767px) {
          margin-bottom: 0px;
          font-size: 14px !important;
          line-height: 20px;
        }
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }

}
.teaser-wrapper{
    a{
        display: block;
        transition: .2s ease-in-out;
        margin-bottom:25px;
        
        @media screen and (min-width: 992px) {
            &:hover, &:focus, &:hover {
                box-shadow: 1px 1px 8px rgba(74,74,74,0.2);
                
                .teaser-text-content{
                    h5, .h5, h3, .h3, .more, p{
                        color: #608ABD;
                        text-decoration: none;
                    }
                } 
            }
        }
    }

    .teaser-text-content{
        position: relative;
        padding:20px 20px 50px 20px!important;
        background-color:#FFFFFF;
        
        h5, .h5{
            font-family: "Sofia Pro",sans-serif; font-size:14px;
            margin-bottom:15px;
            transition: .2s ease-in-out;
        }
        
        h3, .h3{
            font-family: "Sofia Pro",sans-serif; font-size:18px; color:#4A4A4A; text-transform:none; letter-spacing:0;
            margin-bottom:15px;
            transition: .2s ease-in-out;
        }
        
        .more{
            position: absolute; bottom:25px; left:20px;
            font-size:12px;
            transition: .2s ease-in-out;
        }
        
        p{
            font-family: "Sofia Pro",sans-serif; font-size:16px; color:#4A4A4A; text-transform:none;
            transition: .2s ease-in-out;
        }
    }
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.routing-standard-page {

  hr {
    border-color: rgba(29, 55, 86, .3) !important;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

    
  h4, .h4, h1.h4 { 
    font-family: "Sofia Pro",sans-serif!important;
    font-size: 12px;
    a {
        font-size:14px; letter-spacing:1px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }

  }

  margin-top: 15px;
  .show-on-mobile {
    border: 0.5px solid rgba(29, 55, 86, 0.5);
  }

  .links-infos {
    float: right;

    @media screen and (max-width: 600px) {
      float: left;
      margin-bottom: 15px;
    }

    .btn-login {

      display: inline-block;

      border: 1px solid $primary_color;
      margin-left: 40px;
      text-transform: uppercase;
      font-size: 13px !important;
      padding: 6px 15px 6px 6px;

      @media screen and (max-width: 768px) {
        margin-top: 15px;
        margin-left: 0px;
      }

      @media screen and (min-width: 768px) and (max-width: 990px) {
        float: right;
      }

      @media screen and (max-width: 767px) {
        margin-top: 0px;
        font-size: 14px;
        margin-left: 0px;
        padding: 6px 15px 6px 6px;
      }

      &:hover {
        border: 1px solid $secondary_color;
      }

      .icon-locked {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 8px;
        font-size: 20px;
      }
    }

    .links {

      & > a {
        text-transform: none;
        font-size: 14px;
      }

      @media screen and (min-width: 768px) and (max-width: 990px) {
        float: right;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 25px;
        display: block;
      }
    }
  }

  h6, .h6 {
    font-weight: 400;
    color: $primary_color;
    font-size: 30px; font-family: "Sofia Pro",sans-serif;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }

    &.title-lg {
      font-size: 50px !important;
      font-weight: 800;
      @media screen and (max-width: 767px) {
        font-size: 30px !important;
      }
    }
  }

}

.brand-conent{
    > .row{
            margin-top:45px; 
    }
    .brand{
        min-height:125px;
        > div{
            display: flex; align-content: center; justify-content: flex-start;
            height:100%; width:100%;
            border-top:1px solid transparent;
            border-color: rgba(29, 55, 86, 0.3);
        }
    }
    img{
        display: flex; align-self: center;
        width:100%; max-width:270px;
        padding: 10px 0;
        
        @media screen and (min-width: 768px) {
            max-width:270px;
        }
    }
}
.small-note{
    margin-top:20px;
    font-size:11px;
}
.wm-page {

  .claim.claim-standard {
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .stat-number {
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .stat-text {
    font-size:18px !important;
      &.stat-text-small{
          font-size:16px!important;
      }
  }

  .stat-col-last {
    padding-right: 0px;
  }

  .bg-custom-light {
    padding-bottom: 15px;
  }
  .row {
    &.standard-page-wrapper {

      margin-top: 30px;
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .col-md-3 {
      @media screen and (max-width: 767px) {
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }
  }
}

.sc-page {
  .row {
    &.standard-page-wrapper {
      @media screen and (max-width: 767px) {
        margin-top: 0px;

        .title-section {
          h4 {
            @media screen and (max-width: 767px) {
              margin-bottom: 0px;
            }
          }
        }
      }

      .standard-page-content {

        hr {
          margin-top: 5px;
        }
      }
    }
  }
}

.sc-page-custom {
  .standard-page-wrapper {

    p {
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        margin-bottom: 35px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }

    }
    h6 {
        font-size: 18px;
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        margin-bottom: 35px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px !important;
      }
    }
    > div {
      a.more {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .more {
      font-size: 16px;
      line-height: 20px;
      display: inline-block;
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        margin-bottom: 35px;
        font-size: 18px;
      }
      .icon-arrow-right {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}

.lt-page {

  p {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  .main-article {
    p {
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        margin-bottom: 35px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

}

.sr-page {
  .bg-custom-light {
    @media screen and (max-width: 767px) {
      padding-bottom: 10px;
    }
  }
  .claim {
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .post-details-title {
    @media screen and (max-width: 767px) {
      font-size: 18px !important;
    }

  }
  .post-details-content {
    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .standard-page-wrapper {
    margin-top: 0px;
  }

  .title-section {
    @media screen and (max-width: 767px) {
      padding-bottom: 0px !important;
    }
  }

}

.post-routing-link {
  margin-bottom: 0px;
  letter-spacing: 1px; font-size: 12px !important; color: $dark_color
}

.important-information {
    float:left;
    width:100%;
    
  .information-links {
    text-transform: none;
    font-size: 16px; font-weight: 600 !important; line-height: 18px;
    margin-top: 20px; margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      margin-top: 8px;
      margin-bottom: 13px;
    }
      
  }

  .icon-arrow-right-rounded {
    float: right;
    font-weight: 600;

    &.single-line {
      @media screen and (max-width: 767px) {
        margin-bottom: 18px;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }

    @media screen and (max-width: 767px) {
      font-size: 15px !important;
      margin-top: 20px;
    }

    &:before {
      @media screen and (max-width: 767px) {
        padding: 8px;
      }
    }
  }
}

.rounded-number {
  position: relative;
  display: inline-block; vertical-align: middle;
  width: 40px;
  height: 40px;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
  transition: .2s ease-in-out;
  margin-left: 0px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border: 1px solid $primary_color;
    border-radius: 50%;

    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }

  }

  @media screen and (max-width: 767px) {
    line-height: 12px;
    padding: 8px;
    width: 30px;
    height: 30px;
  }

}

.title-number {
  display: inline-block; vertical-align: middle;
  margin:0 35px 0 25px; padding-bottom: 0;
  width:calc(50% - 160px);
    @media screen and (max-width: 992px) {
          margin:0 15px 0 25px; 
        width:calc(50% - 100px);
    }
    @media screen and (max-width: 767px) {
          width:calc(100% - 55px);
          margin:0 0 0 10px;
    }
/*
  @media screen and (max-width: 767px) {
    padding-bottom: 0px;
    margin-top: 10px;
    margin-left: 15px;
  }

  @media screen and (min-width: 768px) and (max-width: 990px) {
    display: block;
    margin-bottom: 0px;
    margin-top: 10px;
    margin-left: 0px;

  }*/
}

.section-services {

  font-size:18px;
    h4, .h4{
        font-size:18px;
    }
    p{
        margin-bottom:30px;
    }

  .col-md-6 {
    padding-bottom: 15px;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      padding-bottom: 0px;

    }
  }

    h4{
        letter-spacing:0; font-weight:600;
        
        &.service-links, a.service-links{
            letter-spacing:0; font-weight:600;
        }
    }
  .service-links {

    text-transform: none;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  p.service-links {
    color: $primary_color;
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  a.service-links:hover {
    color: $secondary_color;
    transition: .2s ease-in-out
  }
}

.list-rounded {

  margin-top: 15px;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .col-md-5 {
      margin-top: 15px;
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    color: $primary_color;
    width: 50%;
    display: inline-block;
    float: none;
      vertical-align: top; margin-top: 6px;
      @media screen and (max-width: 767px) {
          width:100%;
      }
      /*
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 14px !important;
      line-height: 20px;
    }*/
  }

  h4 {
      margin:0;
      
    font-size: 12px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .standard-page-inner-content {

    padding-top: 10px;
    padding-bottom: 200px;

    @media screen and (max-width: 767px) {
      padding-bottom: 50px;
    }
  }
}
.stat-perc{
  @media screen and (max-width: 767px) {
  font-size:30px;
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

.table{
    width:100%;
        
    
    tr{
        td, th{
            &:first-child{
                padding-left:0;
            }
            &:last-child{
                padding-left:0;
            }
        }
    }
    th{
        border-top:0; padding-top:0;
        padding-bottom:15px; padding-left:10px; padding-right:10px; 
        color:$primary_color; font-weight:normal;
    }
    thead{
        th{
            border-bottom:1px solid #979797;
        }
    }
    td, th{
        font-size:14px;
    }
    td{
        padding:15px 10px;
        color:#4A4A4A;
        border-top:1px solid #979797;
    }
}