//main: main.scss
footer {
  font-size: 14px;
  font-family: $font_text;
  bottom: 0;
  width: 100%;
  color: $light_color;

  .row {
    margin-top: 15px;
    margin-bottom: 30px;

    @media only screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &.footer-address {
      .row {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

      }

      .footer-address-content {
        display: none;
        margin-bottom: 10px;

        @media only screen and (min-width: 768px) {
          display: block !important;
          height: auto !important;
          margin-bottom: 0px !important;
        }
        .row {
          > div {
            margin-top: 20px;

            @media only screen and (min-width: 768px) {
              margin-top: 0;
            }
          }
        }
      }

      > div {
        > p {
          position: relative;
          padding-right: 50px;
          cursor: pointer;

          &:after {
            content: "\e909";
            position: absolute;
            right: 0;
            top: 50%;
            width: 32px;
            height: 32px;
            padding: 8px;
            border: 1px solid $white;
            border-radius: 50%;
            font-size: 14px;
            line-height: 14px;
            font-family: 'icomoon' !important;
            transform: translateY(-50%);
          }

          @media only screen and (min-width: 768px) {
            padding-right: 0;
            cursor: inherit;

            &:after {
              content: none;
            }
          }
        }

        &.open {
          > p {
            &:after {
              content: "\e908";

              @media only screen and (min-width: 768px) {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  a {
    color: $light_color;
    text-transform: none;
  }

  .row-contact-title {
    margin-top: 10px;
  }

  .logo-footer {
    height: 70px;
    width: 15%;
    float: left;
  }
  .logo-footer-content {
    float: right;
    width: 70%;

    a {
      margin-bottom: 2px;
    }
  }

  #contactsFooter {
    a {
      font-size: 14px;
    }
    p {
      padding-top: 2px;
      padding-bottom: 2px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
    .accordion {
      p {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    @media only screen and (max-width: 768px) {
      .row {
        margin-bottom: 15px;
      }
      a {
        font-size: 14px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
      hr {
        margin-top: 16px;
        margin-bottom: 16px;

      }
    }

    .row-contact-title {
      .titleNav {
        margin-bottom: 10px;
      }
    }
  }

  #footer-nav {
    margin-top: 20px;
    font-size: 14px;
    a {
      font-weight: 400;
      line-height: 25px;
    }

    h3 {
      padding-bottom: 10px;
    }

    .col-last {
      right: 15px;
      position: absolute;
    }

      .row {
          margin-bottom:5px;

          > div {
              margin-bottom:25px;
          }
      }

  }

  .footer-copyright {
    p {
      margin-top: 4px;
      font-size: 14px;
    }

    .row {
      margin-top: 0;
    }
    #footerLinks {
      float: right;

    }
    @media only screen and (max-width: 768px) {
      margin-top: 45px;

      p, p#footerLinks {
        font-size: 14px;
      }
    }
  }

  hr.show-on-mobile {
    margin-top: 20px;
    margin-bottom: 18px;
  }

  .contact {
    margin-bottom: 40px;
  }

  &.v2 {
    padding-top:8px; padding-bottom:2px;

    @media only screen and (max-width: 992px) {
      padding-top:18px; padding-bottom:2px;
    }

    #footer-nav {
      .row {
        > div {
          display:flex; flex-direction:column; align-items:flex-start; justify-content:flex-start;
          margin-bottom:0;

          h3, .h3 {
            margin-top:36px; margin-bottom:3px; padding:0;
            font-size:16px; line-height:24px; color:$white; letter-spacing:0.01em;

            &:first-child {
              margin-top:0;
            }
          }
          a {
            margin-top:12px;
            line-height:16px;
          }
        }
      }
    }

    .footer-copyright {

      .copyright-row{
        display: flex; justify-content: center; flex-direction: column; align-items: center;  justify-content: center; margin: auto; text-align: center;
        
        @media only screen and (min-width: 992px) {
          flex-direction: row; align-items: center;  justify-content: flex-start; margin: 0;
        }
        
      }

      position: relative;
      margin-top:42px; padding-top:34px;

      .row {
        > div {
          display:flex; align-items:center;
        }
      }

      &:before {
        content:'';
        position:absolute; left:15px; top:0;
        width:calc(100% - 30px); height:1px;
        background-color:$grey_color;
        display: none;

        @media only screen and (min-width: 768px) {
        display: block;
        }
      }

      p {
        margin:0;
        line-height: 16px;
      }
      .logo-footer {
        height:42px; width:42px;
        margin-bottom: 10px;

        @media only screen and (min-width: 992px) {
          margin-right:20px;
          margin-bottom: 0;
        }

        span {
          display:block; float:left;
          height:42px; width:42px;

          &:before {
            font-size:42px; line-height:42px;
            opacity:1;
          }
        }
      }
      .footer-links {
        display:flex; flex-direction:row; align-items:center; justify-content:flex-end;
        margin-bottom: 0; padding:0;
        width:100%;
        list-style:none;
        
        li {
          width:auto;
          margin-left:36px;

          a {
            display:block; float:left;
            font-size:14px; line-height:35px; 
            @media only screen and (min-width: 992px) {
              line-height: 16px;
            }
              &:hover {
                color: $light_color;
                @media only screen and (min-width: 992px) {
                  color: $secondary_color;
                }
              }
            
          }

          &.social {
            margin-left:76px;

            a {
              position:relative;
              padding:9px;
              font-size:0; line-height:0;

              img {
                width:17px; height:17px;
              }
              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                border:1px solid $white;
                opacity:0.15;
                border-radius:50%;
                transition:.2s ease-in-out;
              }
              @media only screen and (min-width: 992px) {
                &:hover {
                  &:before {
                    opacity:1;
                  }
                }
              }
            }

          @media only screen and (max-width: 992px){
            margin-left:10px
          }
   
          @media only screen and (max-width: 480px){
            margin-top:18px
          }

          }
          &:first-child {
            margin-left:0;
          }
        }

        @media only screen and (max-width: 992px) {
          justify-content: center;
          margin-top: 25px;
          li {
            margin-left: 10px;
            &.social {
            margin-left: 10px;
            }
          }
        }

        @media only screen and (max-width: 480px) {
          margin-top: 35px;
          flex-direction: column;
          gap: 17px;
          justify-content: flex-start;
        }
        

      }

      @media only screen and (max-width: 768px) {
        margin-top: 0px; padding-top:0px;
        } 


    }
  }
}
