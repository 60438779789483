.welcome-section {
  padding-bottom: 35px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 60px;
  }

  .row {
    align-items: center;
    padding-top: 35px;

    @media only screen and (min-width: 992px) {
      padding-top: 60px;
    }
  }

  h3,
  .h3 {
    margin-bottom: 18px;
    color: $primary_color;

    @media only screen and (min-width:992px) {
      margin-bottom: 28px;
    }
  }

  h2,
  .h2 {
    font-size: 26px;
    line-height: 32px;

    @media only screen and (min-width:992px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  p {
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;

    @media only screen and (min-width:992px) {
      margin-top: 20px;
    }
  }

  .btn-link {
    float: left;
    margin-top: 24px;

    @media only screen and (min-width:768px) {
      float: right;
      margin-top: 0;
    }
  }
}

.insights-section,
.bg-custom-light-standard.bg-light-grey.insights-section {
  padding-top: 35px;
  padding-bottom: 35px;

  @media only screen and (min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .gutter-big {
    padding-top: 17px;

    .insight-teaser {
      margin-top: 0;
      margin-bottom: 30px;

      @media only screen and (min-width: 992px) {
        margin-bottom: 40px;
      }

      @media only screen and (min-width: 1200px) {
        margin-bottom: 67px;
      }
    }
  }

  .insight-teaser {
    display: block;
    float: left;
    margin-top: 26px;

    a {
      display: block;
      float: left;
      width: 100%;

      picture {
        display: block;
        float: left;
        width: 100%;
        margin-bottom: 12px;

        @media only screen and (min-width: 992px) {
          margin-bottom: 30px;
        }
      }

      .cat-date {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 12px;

        @media only screen and (min-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media only screen and (min-width: 768px) {
          flex-direction: row;
          align-items: center;
        }

        @media only screen and (min-width: 992px) {
          margin-bottom: 24px;
        }

        h4,
        .h4,
        time {
          position: relative;
          display: block;
          float: left;
          width: auto;
          margin-bottom: 0;
          margin-right: 17px;
          padding-right: 0;
          font-size: 12px;
          line-height: 14px;
          color: $primary_color;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          @media only screen and (min-width: 576px) {
            margin-right: 0;

            &::before {
              content: none;
            }
          }

          @media only screen and (min-width: 768px) {
            margin-right: 17px;

            &::before {
              content: "";
            }
          }

          &:last-child {
            padding-right: 0;
            margin-right: 0;

            &:after {
              content: none;
            }
          }
        }
      }

      h3,
      .h3 {
        font-size: 18px;
        line-height: 24px;
        color: $primary_color;
        text-transform: inherit;
        transition: .2s ease-in-out;

        @media only screen and (min-width: 768px) {
          font-size: 22px;
          line-height: 28px;
        }
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 16px;
        color: $dark_color;
        letter-spacing: 0.02em;
        font-weight: 500;

        @media only screen and (min-width: 992px) {
          margin-top: 20px;
        }
      }

      @media only screen and (min-width: 992px) {
        &:hover {

          h3,
          .h3 {
            color: $secondary_color;
          }
        }
      }
    }
  }

  .archive-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    margin-top: 40px;
    width: min-content;
    white-space: nowrap;
    text-align: center;

    @media only screen and (min-width: 768px) {
      margin-top: 20px;
    }

    a {
      &.btn-link {
        padding: 9px 24px;
        margin: 0;
      }
    }

    p {
      margin: 17px 0 0 0;
      padding: 0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.26px;
      text-align: center;
      width: 100%;
    }
  }

  .pagination {
    align-items: center;
    justify-content: center;

    ul {
      width: auto;
    }

    .btn-link {
      margin-top: 0;
    }
  }

  .btn-link {
    margin-top: 34px;

    @media only screen and (min-width: 992px) {
      margin-top: 38px;
    }
  }
}

.services-section {
  padding-top: 35px;
  padding-bottom: 35px;

  @media only screen and (min-width: 992px) {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .services-list {
    display: block;
    float: left;
    width: 100%;
    padding: 0;
    list-style: none;

    li {
      display: block;
      float: left;
      width: 100%;
      margin-top: 20px;

      @media only screen and (min-width: 992px) {
        margin-top: 26px;
      }

      &:first-child {
        @media only screen and (min-width: 1200px) {
          margin-top: 0;
        }
      }

      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px;

        @media only screen and (min-width: 576px) {
          flex-direction: row;
          align-items: center;
        }

        @media only screen and (min-width: 768px) {
          padding: 22px 30px;
        }

        @media only screen and (min-width: 992px) {
          padding: 32px 60px;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: 1px solid $secondary_color;
          opacity: 0.3;
          transition: .2s ease-in-out;
        }

        h3, .h3 {
          width: 100%;
          margin-bottom: 0;
          padding-right: 30px;
          font-size: 16px;
          line-height: 20px;
          color: $primary_color;
          letter-spacing: 0.04;

          @media only screen and (min-width: 576px) {
            width: 35%;
          }

          @media only screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 24px;
          }
        }

        p {
          width: 100%;
          margin-bottom: 0;
          padding-top: 10px;
          color: $dark_color !important;

          @media only screen and (min-width: 576px) {
            width: 62%;
            padding-right: 30px;
            padding-top: 0;
          }
        }

        span {
          width: 100%;
          margin-top: 14px;
          font-size: 16px;
          line-height: 16px;
          color: $primary_color;
          text-align: right;
          font-weight: bold;
          transition: .2s ease-in-out;

          @media only screen and (min-width: 576px) {
            width: 3%;
            margin-top: 0;
          }
        }

        @media only screen and (min-width: 992px) {
          &:hover {
            &:before {
              opacity: 1;
            }

            span {
              color: $secondary_color;
              transform: translateX(20px);
            }
          }
        }
      }
    }
  }
}

.map-section {
  position: relative;
  padding-top: 35px;
  padding-bottom: 45px;
  overflow: hidden;

  @media only screen and (min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 100px;
    min-height: 720px;
  }

  .map-bkg {
    display: none;
    position: absolute;
    right: 0;
    top: -20px;
    width: 572px;
    height: 740px;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: rgb(239, 239, 239);
      background: linear-gradient(180deg, rgba(239, 239, 239, 0) 0%, rgba(239, 239, 239, 1) 100%);
      z-index: 1;
    }

    >span {
      position: absolute;
      width: 16px;
      height: 20px;
      background-image: url('../img/map-pin.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;

      &.active {
        background-image: url('../img/map-pin-active.svg');
      }
    }

    @media only screen and (min-width: 992px) {
      display: block;
      right: -92px;
    }

    @media only screen and (min-width: 1200px) {
      right: 0;
    }

  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.accordion-wrapper {
  display: block;
  float: left;
  width: 100%;
  margin-top: 20px;

  @media only screen and (min-width: 992px) {
    margin-top: 40px;
  }

  .accordion-block {
    float: left;
    width: 100%;
    padding: 0 24px;
    margin-bottom: 24px;
    border-radius: 2px;
    transition: .3s ease-in-out;

    @media only screen and (min-width: 992px) {
      padding: 0 28px;
      margin-bottom: 28px;
    }

    h3,
    .h3 {
      margin: 0;
      padding: 0;
      transition: .3s ease-in-out;

      button {
        position: relative;
        width: 100%;
        padding: 3px 50px 3px 0;
        border: 0 none;
        outline: none !important;
        font-size: 16px;
        line-height: 24px;
        color: $primary_color;
        font-weight: 400;
        text-align: left;
        background-color: transparent;
        transition: .3s ease-in-out;

        &:before {
          content: "\e909";
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
          height: 30px;
          font-size: 10px;
          line-height: 30px;
          color: $primary_color;
          font-family: 'icomoon' !important;
          font-weight: bolder;
          font-style: normal;
          text-align: center;
          transition: .3s ease-in-out;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 30px;
          height: 30px;
          border: 1px solid $primary_color;
          border-radius: 50%;
          transition: .3s ease-in-out;
        }

        @media only screen and (min-width: 992px) {
          font-size: 18px;

          &:hover {
            color: $secondary_color;

            &:before {
              color: $secondary_color;
            }

            &:after {
              border-color: $secondary_color;
            }
          }
        }
      }
    }

    .accordion-content {
      display: none;
      float: left;
      width: 100%;
      padding-top: 12px;

      .contact-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        span {
          display: block;
          float: left;
          min-width: 44%;
          padding-right: 30px;

          &:last-child {
            a {
              margin-top: 12px;
            }

          }

          p {
            margin: 0 !important;
            font-size: 15px;
            line-height: 22px;
            font-weight: 400;
            color: $dark_color;

            >a {
              &:hover {
                color: $primary_color;

                @media only screen and (min-width: 992px) {
                  color: $secondary_color;
                }
              }
            }
          }

          >a {
            position: relative;
            display: block;
            float: left;
            margin-top: 22px;
            padding: 6px;
            font-size: 12px;
            line-height: 14px;
            color: $primary_color;
            font-weight: 800;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            transition: .2s ease-in-out;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background-color: $secondary_color;
            }
          }

          &:nth-child(2) {
            min-width: 56%;
            padding-right: 0;

            p {
              font-size: 13px;
            }
          }

          @media only screen and (max-width: 576px) {
            width: 100%;
            margin-top: 10px;
          }

        }
      }
    }

    &.open {
      padding: 24px;
      background-color: $white;

      @media only screen and (min-width: 992px) {
        padding: 28px;
      }

      h3,
      .h3 {
        button {
          &:before {
            content: "\e908";
          }
        }
      }
    }
  }






}


.row {
  &.gutter-big {
    margin-left: -15px;
    margin-right: -15px;

    @media only screen and (min-width: 768px) {
      margin-left: -25px;
      margin-right: -25px;
    }

    @media only screen and (min-width: 992px) {
      margin-left: -20px;
      margin-right: -20px;
    }

    @media only screen and (min-width: 1200px) {
      margin-left: -49px;
      margin-right: -49px;
    }

    >div {
      padding-left: 15px;
      padding-right: 15px;

      @media only screen and (min-width: 768px) {
        padding-left: 25px;
        padding-right: 25px;
      }

      @media only screen and (min-width: 992px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media only screen and (min-width: 1200px) {
        padding-left: 49px;
        padding-right: 49px;
      }
    }
  }
}



.breadcrumb-wrapper {
  margin-bottom: 35px;

  @media only screen and (min-width: 992px) {
    margin-bottom: 70px;
  }

  .container {
    padding-left: 0;
  }

  .breadcrumb {
    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        color: $primary_color;

        &.active {
          color: $primary_color;
        }

        a {
          float: left;
          display: block;
          color: $dark_color;

          @media only screen and (min-width: 992px) {
            &:hover {
              color: $secondary_color;
            }
          }
        }

        &:not(:last-child)::after {
          content: "-";
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}

.row {

  &.insights-hero,
  .insights-hero,
  .intro-article {
    .text-article {
      margin-top: 0;
      padding-right: 0;

      @media only screen and (min-width: 768px) {
        padding-right: 30px;
      }

      @media only screen and (min-width: 992px) {
        padding-right: 90px;
      }
    }

    picture {
      display: block;

      &.fullwidth {
        @media only screen and (min-width: 768px) {
          width: calc(100% + 30px);
        }

        @media only screen and (min-width: 992px) {
          width: calc(100% + 90px);
        }
      }
    }
  }

  .intro-article {
    picture {
      margin-top: 30px;

      @media only screen and (min-width: 992px) {
        margin-top: 60px;
      }
    }
  }

  &.insights-hero,
  .insights-hero {
    h1 {
      margin-bottom: 29px;
    }

    .text-article,
    .intro-article {
      p {
        color: $dark_color;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.32px;
      }

      time+h1 {
        float: left;
        width: 100%;
        margin-top: 36px;
      }

      picture {
        >img {
          margin-top: 0;
        }
      }
    }

    header {
      margin-bottom: 60px;
    }
  }
}

.main-article {
  >* {
    float: left;
    width: 100%;
    padding-right: 0;

    @media only screen and (min-width: 768px) {
      padding-right: 30px;
    }

    @media only screen and (min-width: 992px) {
      padding-right: 90px;
    }
  }
}

.text-article {
  margin-top: 45px;

  @media only screen and (min-width: 768px) {
    margin-top: 60px;
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    float: left;
    width: 100%;
    margin-bottom: 25px;
  }

  /*>*:first-child{
    margin-top:0; padding-top:0;
  }*/
  >*:last-child {
    margin-bottom: 0;
  }
}

.filter-wrapper {
  margin-top: 20px;

  h4 {
    margin-bottom: 14px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.26px;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0px;
    gap: 15px;
    margin: 0;

    li {
      a {
        display: block;
        padding: 10px 15px 11px 15px;
        border: 1px solid transparent;
        background-color: rgba($secondary_color, 0.2);
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        transition: .2s ease-in-out;


        &.active {
          color: $secondary_color;
          border-color: $secondary_color;
          background-color: transparent;
        }

        &:hover {
          color: $primary_color;
        }

        @media only screen and (min-width: 768px) {
          &:hover {
            color: $secondary_color;
            border-color: $secondary_color;
            background-color: transparent;
          }
        }
      }
    }

  }
}

.download-section {
  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: 992px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.download-wrapper {
  h3 {
    color: $primary_color;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin: 0;
  }

  .btn-link {
    float: right;
    padding: 9px 24px;
  }

  ul {
    float: left;
    padding-left: 0px;
    border-top: 2px solid rgba($secondary_color, 0.2);
    margin-bottom: 24px;
    list-style: none;

    li {
      float: left;

      &.download-item {
        padding: 13px 0;
        border-bottom: 2px solid rgba($secondary_color, 0.2);

        @media only screen and (min-width: 768px) {
          padding: 13px 21px;
        }

        time {
          float: left;
          display: block;
          width: 100%;
          color: $primary_color;
        }

        h3 {
          font-size: 14px;
          font-style: normal;
          color: $primary_color;
          font-weight: 500;
          line-height: 20px;
          text-transform: none;
          letter-spacing: 0;
          margin: 0;
          padding: 0;
          transition: .2s ease-in-out;
        }

        a {
          display: block;
          float: left;
          width: 100%;
          padding-right: 65px;
          transition: .2s ease-in-out;

          &:hover,
          &:focus {
            time {
              color: $primary_color;
            }
          }

          @media only screen and (min-width: 992px) {
            &:hover {
              time {
                color: $primary_color;
              }

              h3 {
                color: $secondary_color;

                &:after {
                  color: $secondary_color;
                }
              }
            }
          }

          h3 {
            float: left;
            position: relative;
            width: 100%;

            &:after {
              content: "\e905";
              display: block;
              position: absolute;
              top: 5px;
              right: -65px;
              font-family: "icomoon";
              color: $primary_color;
              font-size: 24px;
              font-weight: 700;
              transition: .2s ease-in-out;
            }
          }
        }
      }
    }
  }
}

time {
  float: left;
  display: block;
  width: 100%;
  color: $primary_color;
  font-size: 12px;

  &::before {
    content: '';
    display: inline-block;
    transform: translateY(-50%);
    top: 50%;
    position: relative;
    background-color: $grey_color;
    width: 4px;
    height: 4px;
    margin-right: 17px;
    border-radius: 50%;
    line-height: 18px;
  }
}


.insight-heading {

  padding-bottom: 35px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 50px;
  }

  .insights-hero {
    header {
      margin-bottom: 0px;
    }

    .intro-article {

      h1 {
        font-size: 35px;

        @media only screen and (min-width: 992px) {
          font-size: 42px;
        }
      }

      p {
        font-size: 18px;
      }

    }
  }

  .summary {

    .summary-wrapper {

      .summary-mobile {

        h2{
          margin-bottom: 29px;
          font-size: 35px;
        }

        p{
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: .32px;
          margin-bottom: 25px;
        }

        time {
          margin-bottom: 20px;
        }
      }

      .summary-info {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: 768px) {

        }

        .reading-time{
          order: 2;
          margin-bottom: 8px;
          @media only screen and (min-width: 768px) {
          order: 1;
          margin-bottom: 20px;
          }
        }
        .written-by {
          order: 1;
          width: 30%;
          @media only screen and (min-width: 768px) {
          order: 2;
          }
        }

        .author {
          order: 3;
          @media only screen and (min-width: 768px) {

          }
        }

        .excerpt{
          order: 4;
          width: 100%;
          margin-bottom: 25px;
          @media only screen and (min-width: 768px) {
          margin-bottom: 16px;
          }
        }


      }
    }

    p {
      color: $primary_color;
    }

    h3 {
      color: $primary_color;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 20px;
      width: 30%;

      @media only screen and (min-width: 992px) {
        padding-top: 25px;
      }

      @media only screen and (min-width: 768px) {
        margin-bottom: 32px;
        width: 100%;
      }

    }

    .reading-time {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 20px;
      width: 70%;
      font-size: 13px;
      font-weight: 400;

      @media only screen and (min-width: 768px) {
        width: 100%;
        justify-content: flex-start;
      }

      font-size: 14px;

      &::before {
        content: "";
        display: block;
        position: relative;
        margin-right: 11px;
        margin-top: 3px;
        min-height: 16px;
        min-width: 16px;
        background: url('../img/Group.svg') no-repeat;
        background-size: cover;
      }

      .time-stamp {
        margin-left: 8px;
      }

    }



    .written-by {
      font-size: 13px;
      font-style: italic;
      font-weight: 100;
      margin-bottom: 8px;
      width: 100%;
    }

    .author {
      margin-bottom: 0px;
      font-size: 18px;
    }

    .excerpt {
      font-size: 13px;
    }

    .summary-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      border-bottom: 1px solid $primary_color;

      @media only screen and (min-width: 768px) {
        display: block;
        margin-bottom: 20px;
        margin-top: 50px;
        padding-left: 20px;
        border-left: 1px solid $primary_color;
        border-bottom: none;

      }
    }
  }

  .main-article {
    .video-wrapper {

      video {
        width: 100%;
        height: 100%;
        margin: 45px 0 15px 0;

        @media only screen and (min-width: 768px) {
          margin-top: 60px;
        }
      }

      .video-description {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }

    .text-article {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.7rem;
      }

      h2 {
        font-size: 30px;

        @media only screen and (min-width:768px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: 20px;
        color: $primary_color;
      }

      img {
        margin-bottom: 25px;
      }

      .img-right {
        img {
          @media only screen and (min-width:992px) {
            margin-bottom: 0px;
            float: right;
            width: 50%;
            padding-bottom: 25px;
            padding-left: 25px;
          }
        }
      }

      .img-left {
        img {
          @media only screen and (min-width:992px) {
            margin-bottom: 0px;
            float: left;
            width: 50%;
            padding-bottom: 25px;
            padding-right: 25px;
          }
        }
      }

      .fullwidth {
        img {
          @media only screen and (min-width:992px) {
            margin-bottom: 25px;
            width: 100%;

          }
        }
      }

    // new quote section
    .quote{
      display: flex; flex-wrap: wrap; margin-top: 50px; justify-content: flex-end;

      blockquote{
        font-size: 18px; line-height: 30px; font-style: italic; position:relative; letter-spacing: 0.44px;
        padding-right: 32px; padding-left: 32px; padding-top: 33px; padding-bottom: 48px;
        margin-bottom: 0px;
        width:100%;
        color: $primary_color;

        @media only screen and (min-width:576px) {

        }

        @media only screen and (min-width:992px) {
          width: 60%;
          padding-left: 12px;
          font-size: 22px;
          }

        @media only screen and (min-width:1200px) {
          padding-left: 92px;
          width: 70%;
        }



       &::after{
          content: '';
          display: block; position: absolute;
          bottom: 35px; right: 23px;
          height: 24px;
          width: 29px ;
          background-image: url('../img/right-tick.svg');
          background-repeat: no-repeat;
          background-size: contain;

          @media only screen and (max-width:360px) {
            bottom:15px;
          }

        }

        &::before{
          content: '';
          display: block; position: absolute;
          top: 0px; left: 0px;
          height: 24px;
          width: 29px ;
          background-image: url('../img/left-tick.svg');
          background-repeat: no-repeat;
          background-size: contain;

          @media only screen and (min-width:992px) {
            left:0px;
          }

          @media only screen and (min-width:1200px) {
            left:30px;
          }


        }


      }

      figure{
        width: 40%;
        order: 3;
        margin-bottom: 0px;

        @media only screen and (min-width:768px) {
          margin-top: 0px;
        }

        @media only screen and (min-width:992px) {
          order: 2;
          width: 30%;
        }

        @media only screen and (min-width:1200px) {
          margin-top: 0px;
          width: 30%;
        }

        img{

          margin-bottom: 25px;

        }

      }

      figcaption{
        order: 2; width: 50%;
        display: flex; align-items: center; gap: 10px;
        margin-left: 0px; padding-right: 20px;

        @media only screen and (min-width:992px) {
          order: 3;
          width: 100%;
          padding-right: 0; padding-left: 0;
        }


        @media only screen and (min-width:1200px) {
          margin-left: 32px;
        }


        font-size: 14px; line-height: 20px;
        &::before{
          @media only screen and (min-width:992px) {
            content: "";
            display: block;
            height: 1px;
            width: 40px;
            background-color: $dark_color;
          }
        }
      }
    }

    }

    a {
      text-decoration: underline;
      color: $primary_color;
      font-weight: bold;
    }

    // Bottone download PDF new-detail-insights
    .btn-link.download{
      display: flex; align-items: center;
      float: left;
      margin-left: 0;
      padding: 10px 24px;
      width: auto;
      text-decoration: none;
      font-size: 14px; line-height: 20px; font-weight: 500;

      &:hover{
        color: $secondary_color;

        &::before{
          color: $secondary_color;
        }
      }

      &::before{
        content: "\e905";
        vertical-align: middle;
        width: 19px; height: 24px;
        color: $primary_color;
        font-family: "icomoon"; font-size: 20px; line-height: 30px; font-weight:700;
        margin-right: 10px; margin-top: -5px;
        transition: .2s ease-in-out;
      }
    }
  }

  .article-slider-section {
    margin-top: 45px;

    @media only screen and (min-width:768px) {
      margin-top: 60px;
    }

    h2 {
      margin-bottom: 25px;
    }

    p {
      font-size: 13px;
      font-weight: 400;
    }

    .slick-dots {
      position: relative;
      bottom: 0;

      li {
        &:before {
          content: none;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          border: 1px solid $primary_color;
          transform: translate(-50%, -50%);
        }

        &.slick-active {
          &:after {
            width: 10px;
            height: 10px;
            background-color: $primary_color;
          }
        }
      }
    }

    .gallery-slider-wrapper {

      @media only screen and (min-width:576px) {
        // margin-left: 15px; margin-right: 15px;
      }

      .gallery-slider {
        .slick-arrow {

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 44px;
            height: 44px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          display:none !important;
          width: 10px;
          height: 17px;
          background: url('../img/chevron-right.svg') no-repeat;
          background-color: rgba($secondary_color, 0.7);
          background-position: center center;

          &.slick-prev {
            transform: rotate(180deg) translateY(0);
            transform-origin: center center;
            left: 25px;
            z-index: 1;

            @media only screen and (min-width:576px) {
              display: block !important;
              left: -40px;
              top: calc(50% - 23px);
              background-color: transparent;
            }
          }

          &.slick-next {
            transform: translateY(0);
            right: 25px;

            @media only screen and (min-width:576px) {
              display: block !important;
              right: -40px;
              top: calc(50% - 23px);
              background-color: transparent;
              background-size: contain;
            }
          }

        }
      }
    }

    .slick-dotted {
      &.slick-slider {
        margin-bottom: 15px;

        @media only screen and (min-width:992px) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.heading-about-us{
  .claim {
    padding-left: 15px;
  }
  img {
    margin-top: 0;

    @media only screen and (min-width:768px) {
      //margin-top: 150px;
    }

    @media only screen and (min-width:992px) {
      margin-top: 80px;
    }

    @media only screen and (min-width:1200px) {
      margin-top: 15px;
    }

  }
}
