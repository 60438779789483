//main: main.scss

body {
  background-color: $light_color;
  color: $dark_color;
  font-family: $font_text;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

ul{
    li{
        font-size:18px; font-weight: 400;

          @media only screen and (max-width: 768px) {
            font-size: 16px; line-height: 22px;
          }
    }
}
p {
  font-size: 18px;
  display: block;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
}

h1, h2, h5 {
  font-family: $font_title;
  color: $primary_color;

}

h3 {
  font-family: $font_text;
  color: $secondary_color;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
}

h4 {
  color: $primary_color;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;

}

a {
  color: $primary_color;
  //text-transform: uppercase;
  //letter-spacing: 1px;
  //font-size: 14px;
  cursor: pointer;
  transition: .2s ease-in-out;
}

a:hover, a:focus {
  color: $secondary_color;
  text-decoration: none;
}

hr {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 0.5px;

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.claim {
  margin-top: 50px;
  margin-bottom: 40px;
  padding-left: 0;

  h2, .h2 {
    font-size: 1.3rem;
    line-height: 1.3;

    @media only screen and (min-width: 768px) {
      font-size: 2rem;
      line-height: 1.2;
    }
  }

  h4, .h4 {
    padding-bottom: 40px;
    font-family: "Sofia Pro",sans-serif!important;
    font-weight: 500; text-transform: uppercase;
    font-size: 14px; letter-spacing:1px;
    @media only screen and (max-width: 768px) {
      padding-bottom: 10px;
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-top: 15px;
    margin-bottom: 20px;
  }

}

.more {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  font-weight: 400;
  .icon-arrow-right {
    font-size: 10px;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
}

.show-on-mobile {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mail-link {
  text-transform: none;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

}

//desktop show
@media only screen and (min-width: 767px) {
  .show-on-mobile {
    display: none;
  }
}

//mobile show
@media screen and (max-width: 767px) {
  #lang-ita, #lang-eng {
    display: none;
  }

  #footer-nav {
    display: none;
  }
}

.slider-nav {
  margin-left: 5px;
  margin-right: 5px;
}

a.slider-nav:hover {
  color: $light_color;
}
a {
  &.phone {
    @media only screen and (min-width: 992px) {
      cursor:default;
      pointer-events:none;
    }
  }
}

.show-on-desktop {
  @media only screen and (max-width: 767px) {
    display: none;

  }
}
.pagination{
    ul{
        width:100%;
        margin:0; padding:0;
        @media only screen and (max-width: 767px) {
            text-align: center;
        }
    }
    .active{
        .btn-link {
            cursor:default; pointer-events: none;
            border-width: 2px; font-weight: bold; font-size: 15px !important;

            &:hover, &:focus {
                color: $primary_color;
                border-color: $primary_color;
                text-decoration: none;
            }
        }
    }
    .btn-link {
        display: inline-block; overflow: hidden;
      color: $primary_color;
      border: 1px solid $primary_color;
      margin-left: 15px;
      text-transform: uppercase; text-align: center;
      font-size: 13px !important; letter-spacing:1px;
        min-width:31px; height:31px; line-height:27px;
      padding: 0 5px;
      transition: .2s ease-in-out;

        &.active{
            border:0;
        }

      @media only screen and (max-width: 767px) {
        margin: auto;

      }

      &:hover, &:focus {
        color: $secondary_color;
        border-color: $secondary_color;
        text-decoration: none;
      }
    }
}
.btn-link {
  color: $primary_color;
  border: 1px solid $primary_color;
  margin-left: 15px;
  text-transform: uppercase;
  font-size: 13px !important; letter-spacing:1px;
  padding: 5px 18px 5px 18px;
  transition: .2s ease-in-out;

  @media only screen and (max-width: 767px) {
    margin: auto;

  }

  @media only screen and (min-width: 992px) {
    &:hover, &:focus {
      color: $secondary_color;
      border-color: $secondary_color;
      text-decoration: none;
    }
  }

  &.big {
    padding:15px 30px;
    margin:0;
    font-size:14px!important; line-height:20px;
  }
  &.light {
    color:$white;
    border: 1px solid $secondary_color;

    @media only screen and (min-width: 992px) {
      &:hover, &:focus {
        border-color: $white;
      }
    }
  }
}

p.lp {
  font-style: italic !important;
  font-weight: 200 !important;
    font-size: 16px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-60 {
  padding-bottom: 60px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-60 {
  margin-bottom: 60px;
}

img {
  max-width: 100%;
  height: auto;
}

.mt-43 {
  margin-top: 43px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pt-30-mobile {
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
  }
}

.pt-20-mobile {
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
  }
}

.pb-30-mobile {
  @media only screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.pb-20-mobile {
  @media only screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}

.pb-15-mobile {
  @media only screen and (max-width: 767px) {
    padding-bottom: 15px;
  }
}

.pb-10-mobile {
  @media only screen and (max-width: 767px) {
    padding-bottom: 10px;
  }
}

.pb-5-mobile {
  @media only screen and (max-width: 767px) {
    padding-bottom: 5px;
  }
}

.show-on-tablet {
  @media screen and  (min-width: 768px)and  (max-width: 990px) {
    display: none;
  }
}
