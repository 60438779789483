//main: main.scss

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * sofia-pro:
 *   - http://typekit.com/eulas/00000000000000003b9b1a96
 *   - http://typekit.com/eulas/00000000000000003b9b1a95
 *   - http://typekit.com/eulas/00000000000000003b9b1a98
 *   - http://typekit.com/eulas/00000000000000003b9b1a97
 *   - http://typekit.com/eulas/00000000000000003b9b1a9c
 *   - http://typekit.com/eulas/00000000000000003b9b1a9b
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-03-07 05:56:55 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=cso2alv&ht=tk&f=24543.24544.24547.24548.24549.24552&a=4639654&app=typekit&e=css");

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/572508/00000000000000003b9b1a96/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/572508/00000000000000003b9b1a96/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/572508/00000000000000003b9b1a96/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n3&v=3") format("opentype");
font-style:normal;font-weight:300;
}

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/111def/00000000000000003b9b1a95/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/111def/00000000000000003b9b1a95/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/111def/00000000000000003b9b1a95/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i3&v=3") format("opentype");
font-style:italic;font-weight:300;
}

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/5855b2/00000000000000003b9b1a98/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
font-style:normal;font-weight:400;
}

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/98f96f/00000000000000003b9b1a97/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
font-style:italic;font-weight:400;
}

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
font-style:normal;font-weight:600;
}

@font-face {
font-family:'Sofia Pro';
src:url("https://use.typekit.net/af/e344c9/00000000000000003b9b1a9b/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/e344c9/00000000000000003b9b1a9b/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/e344c9/00000000000000003b9b1a9b/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("opentype");
font-style:italic;font-weight:600;
}

.tk-sofia-pro { font-family: 'Sofia Pro',sans-serif; }


@font-face {
  font-family: 'Noe Display';
  src: url('../fonts/NoeDisplay-Regular.woff2') format('woff2'),
  url('../fonts/NoeDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
