.slider-nav-story {
}

.pannello-story {

  padding:  20px 0px 20px 20px;
  @media screen and (max-width: 767px) {
    margin: 20px;
  }

  p{

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }

    &.small{
      font-size: 14px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    &.text-muted{
      color: $dark_color !important;
      line-height: 20px ;
    }
  }

  h2{
    font-size: 50px;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      font-size: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }


}






