.download-list {

  .download-list-date {
    padding-top: 5px;
    text-transform: uppercase;
    color: $dark_color; font-size: 12px;

    @media screen and (max-width: 767px) {
      margin-bottom: 0px !important;
    }/*
    span{
      font-size: 12px;

    }*/
  }
  .download-list-title {
    text-transform: none;
    font-size: 18px ;
    font-weight: 600;

    @media screen and (max-width: 767px) {
      font-size: 16px ;
      margin-top: 0px !important;
    }
    .download-list-sub-title{
      color: $dark_color;
      font-weight: 400;
    }
  }
  .download-list-icon{
    margin-top: 12px;

    .icon-download{
      margin-right: 15px;
      font-weight: 600;
      float: right;
      font-size: 30px;
    }
  }


}
.download-buttons, .icons-buttons{
    margin:40px 0 0 0;
    > li > a, > li{
        display: inline-block;
        margin:5px 0;
        i{
            font-size:33px; margin-right:15px; font-weight:300; vertical-align: middle;
        }
        .download-title, .icons-title{
            vertical-align: middle;
            letter-spacing: 0; font-weight: 600; font-size: 18px; text-transform: none;
        }
        .icons-img{
            margin-right:25px;
        }
    }
}
.icons-buttons > li, .icons-buttons > li > a{
    display: flex;
    
    .icons-title{
        display: flex;
    }
    .icons-img{
        display: flex;
    }
}
