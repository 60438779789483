.cookie-alert { 
    position:fixed; left:0; bottom:0;
	width:100%;
    padding:16px;
    text-align:center; 
	background:#000000;
    z-index:1042;
    
    @include media-breakpoint-up(md) {
        padding:40px 0;
    }
    
    .content {
        width:100%;
        display:block; float:left;
    }
    p {
        display:block; float:left; 
        width:100%; min-height:30px;
        margin:0 4px; padding:0;
        font-size:0px; line-height:30px; color:#ffffff; text-align:center; 
        
        span, a {
            display:inline-block; float:none;
            font-size:14px; line-height:20px;
        }
    }
    a {
        display:block; float:right;
        margin:0 0 0 5px;
        text-decoration:none!important; color:#FFFFFF;
    }
    a.closeCookie {
        display: inline-block;
        margin:10px 20px 0 20px; padding:0;
        border-radius:15px; -webkit-border-radius:15px; -moz-border-radius:15px;
        text-decoration:none!important; color:#ffffff; font-size:18px; line-height:30px; font-weight:700;
        [class^="icon-"], [class*=" icon-"]{
            font-size:25px; line-height: 40px;
        }
        
        i {
            line-height:28px;
        }
            @include media-breakpoint-up(lg) {
            margin:0 20px;
        }
    }
}

body:not(.mobile) {
    .cookie-alert {
        a {
            @include media-breakpoint-up(md) {
                &:hover {
                    text-decoration:underline!important;
                }
            }
        }
        a.closeCookie {
            @include media-breakpoint-up(md) {
                &:hover {
                    text-decoration:none!important;
                }
            }
        }
    }
}