.slider-gallery {
    display:none;
    margin-bottom:25px!important;

    @include media-breakpoint-up(md) {
        margin-bottom:45px!important;
    }

    &.slick-initialized {
        display:block;
    }

    .slick-arrow {
        width:60px; height:60px;
        z-index:1;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; filter:alpha(opacity=0); -moz-opacity:0; -khtml-opacity:0; opacity:0;
        -webkit-transition:all .3s ease-out; -moz-transition:all .3s ease-out; -o-transition:all .3s ease-out; transition:all .3s ease-out;

        &:before {
            position:absolute; left:50%; top:50%;
            width:28px; height:28px;
            font-size:28px; line-height:28px;
            -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; filter:alpha(opacity=100); -moz-opacity:1; -khtml-opacity:1; opacity:1;
            -webkit-transform:translate(-50%,-50%); -ms-transform:translate(-50%,-50%); transform:translate(-50%,-50%);
            z-index:2;
        }
        &:after {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            background-color:$black;
            -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; filter:alpha(opacity=50); -moz-opacity:0.5; -khtml-opacity:0.5; opacity:0.5;
        }

        &.slick-prev {
            left:20px
        }
        &.slick-next {
            right:20px
        }
    }

    .slick-dots {
        bottom:-30px;

        li {
            margin:0 5px;

            button {
                position:relative;
                width:14px; height:14px;
                padding:0; margin:0; border:0 none;
                border-radius:50%;
                text-indent:-9999px;
                background-color:transparent;
                -webkit-transition:background-color .3s ease-out; -moz-transition:background-color .3s ease-out; -o-transition:background-color .3s ease-out; transition:background-color .3s ease-out;

                &:after {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    border:1px solid $primary_color;
                    border-radius:50%;
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color:$primary_color;
                    }
                }
            }

            &.slick-active {
                button {
                    background-color:$primary_color;
                }
            }
        }

        @include media-breakpoint-up(md) {
            bottom:-40px;

            li {
                margin:0 6px;

                button {
                    width:18px; height:18px;
                }
            }
        }
    }

    &.single-gallery {
        margin-bottom:0!important;

        .slick-dots {
            display:none!important;
        }
    }

    @include media-breakpoint-up(lg) {
        &:hover {
            .slick-arrow {
                -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; filter:alpha(opacity=100); -moz-opacity:1; -khtml-opacity:1; opacity:1;
            }
        }
    }
}

.hero-slider {
  float:left;
  width:100%;
  margin-bottom:0!important;

  .slick-slide {
    position:relative;

    .content {
      position:absolute; left:0; top:50%;
      width:100%;
      transform:translateY(-50%);
      z-index:2;

      .container {
        @media only screen and (min-width: 1440px) {
          max-width:1340px;
        }
      }

      h1, .h1, h2, .h2 {
        display:block; float:left;
        width:100%;
        margin-bottom:0;
        font-size:32px; line-height:32px; color:$white; letter-spacing:0.02em;

        @media only screen and (min-width:992px) {
          font-size:42px; line-height:42px;
        }
      }
      .label {
        position:relative;
        float:left;
        width:auto;
        padding-bottom:14px; margin-bottom:22px;
        font-size:12px; line-height:14px; color:$white; letter-spacing:0.05em; font-weight:500; text-transform:uppercase;

        @media only screen and (min-width:992px) {
          padding-bottom:17px; margin-bottom:30px;
        }

        &:after {
          content:'';
          position:absolute; left:0; bottom:0;
          width:100%; height:1px;
          background-color:$secondary_color;
        }
      }
      .btn-link {
        float:left;
        width:auto;
        margin-top:25px;

        @media only screen and (min-width:992px) {
          margin-top:35px;
        }
      }
    }
    .image {
      position:relative;
      float:left;
      width:100%;
      padding-bottom:420px;
      background-position:center; background-repeat:no-repeat; background-size:cover;
      z-index:1;

      @media only screen and (min-width:992px) {
        padding-bottom:41.66%;
      }
      @media only screen and (min-width:1600px) {
        padding-bottom:37.66%;
      }
      @media only screen and (min-width:1800px) {
        padding-bottom:31.66%;
      }

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background:rgb(0,0,0); background:linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
        opacity:0.6;

        @media only screen and (min-width:768px) {
          width:75%;
          background:rgb(0,0,0); background:linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          opacity:1;
        }
      }
    }

    &.clean {
      .image {
        &:before {
          width:100%;
          background:rgb(0,0,0); background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          opacity:0.2;
        }
      }
    }
    &.video {
      .video-btn {
        position:absolute; left:50%; top:50%;
        width:64px; height:64px;
        z-index:2;
        transform:translate(-50%,-50%);

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border-radius:50%;
          border:2px solid $white;
        }
        &:after {
          content:'';
          position:absolute; left:50%; top:50%;
          width:0; height:0;
          margin-left:2px;
          border-style:solid; border-width:9px 0 9px 12px; border-color:transparent transparent transparent #ffffff;
          transform:translate(-50%,-50%);
        }
      }
      .close-video {
        position:absolute; right:0; top:0;
        width:48px; height:48px;
        z-index:4;

        i {
          position:absolute; left:0; top:0;
          width:48px; height:48px;
          font-size:20px; line-height:48px; text-align:center; color:$white;
          text-shadow:0px 0px 10px rgba(0,0,0,0.8);
        }

        @media only screen and (min-width:992px) {
          right:20px; top:20px;

          i {
            font-size:24px;
          }
        }
      }
      iframe {
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        z-index:0;
        opacity:0;
        transition:opacity 0.3s ease-out;
        background-color:$primary_color;
      }
      .image {
        &:before {
          width:100%;
          background:rgb(0,0,0); background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          opacity:0.45;
        }
      }

      &.play {
        iframe {
          z-index:3;
          opacity:1;
        }
      }
    }
  }

  .slick-dots {
    bottom:20px;

    li {
      &:before {
        content:none;
      }
      &:after {
        content:'';
        position:absolute; left:50%; top:50%;
        width:6px; height:6px;
        border-radius:50%;
        border:1px solid $white;
        transform:translate(-50%,-50%);
      }

      &.slick-active {
        &:after {
          width:10px; height:10px;
          background-color:$white;
        }
      }
    }
  }

  &.play {
    .slick-dots {
      display:none;
    }
  }
}

.partner-section {
  padding-top:35px!important; padding-bottom:35px!important;
  overflow:hidden;

  @media only screen and (min-width: 992px) {
    padding-top:50px!important; padding-bottom:50px!important;
  }
}

.partner-slider {
  float:left;
  width:calc(100% + 40px);
  margin-left:-20px; margin-right:-20px;
  margin-top:26px; margin-bottom:0!important;

  @media only screen and (min-width: 1200px) {
    width:calc(100% + 60px);
    margin-left:-30px; margin-right:-30px;
  }

  .slick-slide {
    padding-left:20px; padding-right:20px;

    @media only screen and (min-width: 1200px) {
      padding-left:30px; padding-right:30px;
    }

    .partner {
      display:flex; flex-direction:row; align-items:flex-start;
      width:100%;

      picture {
        flex-shrink:0;
        display:flex; justify-content:center; align-items:center;
        width:80px; height:80px;
        margin-right:18px;

        @media only screen and (min-width: 992px) {
          margin-right:22px;
        }

        img {
          max-width:100%; max-height:100%;
          width:auto; height:auto;
        }
      }
      > div {
        margin-top:10px;

        @media only screen and (min-width: 992px) {
          margin-top:20px;
        }
        @media only screen and (min-width: 1200px) {
          margin-top:30px;
        }

        h3, .h3 {
          margin-bottom:0;
          font-size:16px; line-height:24px; color:$primary_color; letter-spacing:0.02em; text-transform:inherit;
        }
        p {
          margin-top:8px; margin-bottom:0;
          font-size:13px; line-height:20px; color:$dark_color;

          @media only screen and (min-width: 992px) {
            margin-top:12px;
          }
          @media only screen and (min-width: 1200px) {
            margin-top:14px;
          }
        }
      }
    }
  }

  .slick-dots {
    position:relative;
    bottom:0;
    margin-top:30px;

    li {
      &:before {
        content:none;
      }
      &:after {
        content:'';
        position:absolute; left:50%; top:50%;
        width:6px; height:6px;
        border-radius:50%;
        border:1px solid $primary_color;
        transform:translate(-50%,-50%);
      }

      &.slick-active {
        &:after {
          width:10px; height:10px;
          background-color:$primary_color;
        }
      }
    }
  }
}
