
.accordion {
  color: $light_color;
  margin: 0;

  &.standard-page-accordion {
    color: $primary_color;
    

    .icon-plus, .icon-minus {
        display: flex;
        width:36px; height:36px; align-self: center;
      margin-top: 0;
      float: right;
      font-size: 18px;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        margin-top: 0;
        font-size: 14px ;

      }

      &:before, &:before {
        padding: 8px;
      }
    }

    a:hover{
      color: $secondary_color !important;
      transition: .2s ease-in-out !important;
      h6{
        color: $secondary_color !important;
        transition: .2s ease-in-out !important;
      }
    }

    h6 {
      font-size: 18px; align-self: center;
      text-transform: none;
      font-weight: 600;width: 100%;
        margin:0;
      @media screen and (max-width: 767px) {
       font-size: 16px;
        margin-bottom: 0px;
      }
    }

    h4 {
      font-style: italic;
      text-transform: none;
      font-size: 18px;width: 100%;
      margin-top: 10px; margin-bottom:0;

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        font-size: 16px;

      }
    }

    .card {
      width: 100%;
      padding: 0 15px 0px 15px;
        .accordion-card-wrapper{
            padding-bottom:60px;
        }
        >div:not(.collapse) {
            align-items: center;
            vertical-align: middle;
            a{
                display: inline-block;
                width: 100%;
                margin: 10px 0;
                
                .row {
                    .col-9{
                        display: flex; flex-direction: column; justify-content: center;
                        width:100%;
                    }
                    .col-3{
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
    p {
      padding-top: 15px; margin-bottom:0;
      color: $dark_color;
    }
  }
}

.card {
  background-color: transparent;
  border: transparent;
}

.btn {
  text-align: inherit;
  padding: 0;
}
